import { AvField, AvForm } from "availity-reactstrap-validation"
import { useEffect, useState } from "react"
import { Button, Card, Col } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Translate } from "../../../Enums/Tranlate"
import Select from "react-select";
import MonthDropDown from "../../../Enums/MonthDropDown"
import YearDropDown from "../../../Enums/YearDropDown"
import EmployeesService from "../../../../services/EmployeesService"
import { toast } from "react-toastify"
import { months } from "../../../Enums/Fixed"

const initial = {
    increment: "",
    type: "",
    effective_date: ""
}
const Benefits = ({employee})=>{
    const [formData, setFormData] = useState(initial)
    const lang = useSelector(state=> state.auth.lang)
    const [params, setParams] = useState({
        month: {
          label: Translate[lang][months[new Date().getMonth()].toLocaleLowerCase()],
          value: months[new Date().getMonth()]
      },
        year: {
          label: `${new Date().getFullYear()}`,
          value: new Date().getFullYear()
        }
      })
    const [loading, setLoading] = useState(false)
    const employeesService = new EmployeesService()

    const changeParams = (e, name) => {
        setParams({...params, [name]: e})
    } 

    const onSubmit = (e) => {
        e.preventDefault();
        let data ={
            employee_id: employee?.id,
            type: formData?.type?.value,
            increase: formData?.increment,
            month: params?.month?.value,
            year: params?.year?.value

        }

        setLoading(true)
        employeesService.benefit(data)?.then(res=>{
            if(res?.status){
                toast.success('Benefits Added Successfully')
            }
            setLoading(false)
        }).catch(()=>setLoading(false))
    }

    return <>
    <Card>
        <Card.Body>
        <AvForm onValidSubmit={onSubmit}>
            <div className="row">
                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                    <label>{Translate[lang].type}</label>
                    <Select
                        placeholder={Translate[lang]?.select}
                        options={[
                            {label: Translate[lang].annual, value: 'annual'},
                            {label: Translate[lang].bonus, value: 'bonus'},
                            {label: Translate[lang].commission, value: 'commission'},
                        ]}
                        value={formData?.type}
                        onChange={(e) => setFormData({...formData, type: e})}
                    />
                </div>
                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                    <AvField
						label ={`${Translate[lang]?.increment}`}
						name ='increment'
						type='number'
						value={formData.increment}
						validate={{
							required: {
								value:true,
								errorMessage: Translate[lang].field_required
							},
						}}
						placeholder={`${Translate[lang]?.increment}`}
						onChange={(e)=> setFormData({...formData, increment: e.target.value})}
					/>
                </div>
                    <Col md={3} sm={6}>
                        {formData.type?.value === "annual" && <label>{Translate[lang]?.effective_date}&nbsp; - &nbsp;</label>}
                        <MonthDropDown
                            params={params} 
                            changeParams={changeParams} 
                        />
                    </Col>
                    <Col md={3} sm={6}>
                        {formData.type?.value === "annual" && <label>{Translate[lang]?.effective_date}&nbsp; - &nbsp;</label>}
                        <YearDropDown
                            params={params} 
                            changeParams={changeParams} 
                        />
                    </Col>
            </div>
         <div className="d-flex justify-content-between mt-3">
            <div></div>
            <Button variant="primary" type="submit" disabled={loading}>{Translate[lang]?.submit}</Button>
         </div>
      </AvForm>
        </Card.Body>
    </Card>
    </>
}
export default Benefits