import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Salaries from "../Salaries";
import Vacations from "../Vacations";
import Deduction from "../Deduction";
import ActiveEmployees from ".//Active";
import Resignation from "./Resignation";
import NoticePeriod from "./NoticePeriod";
import DeletedEmployees from "./Deleted";
import Provision from "../Provision";
import Sick from "../Sick";
import './style.scss'
import { useLocation } from "react-router-dom";

const tabs = ['employees', 'salaries', "vacations", "deduction", "provision", "sick_leave"]
const employeesTabs = ['active', "notice_period", 'resignation', 'termination']

const Employees = () => {
    const [selectTab, setSelectTab] = useState('employees')
    const [selectEmployeesTab, setSelectEmployeesTab] = useState('active')
    const lang = useSelector(state=> state.auth?.lang)
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const location = useLocation()

    useEffect(()=>{
      if(!!location?.state){
        if(location?.state === "resignation") setSelectEmployeesTab("resignation") 
        if(location?.state === "vacations") setSelectTab("vacations") 
      }
    },[location])

  return (
    <Fragment>
      <Card className="mb-3 py-2">
      <div className="custom-tabs-div">
            {tabs?.map((tab,index)=>{
              if(isExist(`view_${tab}`)){
                return <p
                key={index}
                className={`${tab === selectTab ? "activeP" : "" }`}
                onClick={() => setSelectTab(tab)}
              >
                <span></span> {Translate[lang][tab]}
                </p>
              } else {
                return <></>
              }
            })}
          </div>
      </Card>

      {(selectTab === 'employees' && isExist(`view_employees`)) && <>
      <Card className="mb-3">
        <Card.Body className="p-0">
          <div className="tabs-div">
            {employeesTabs?.map((tab,index)=>{
                return <p
                key={index}
                className={`${tab === selectEmployeesTab ? "activeP" : "" }`}
                onClick={() => setSelectEmployeesTab(tab)}
              >
                {Translate[lang][tab]}
              </p>
            })}
          </div>
         </Card.Body>
      </Card>
        {selectEmployeesTab === 'active' && <ActiveEmployees />}
        {selectEmployeesTab === 'resignation' && <Resignation />}
        {selectEmployeesTab === 'notice_period' && <NoticePeriod />}
        {selectEmployeesTab === 'termination' && <DeletedEmployees />}
      </>}
      {(selectTab === 'salaries' && isExist(`view_salaries`)) && <Salaries />}
      {(selectTab === 'vacations' && isExist(`view_vacations`)) && <Vacations />}
      {(selectTab === 'deduction' && isExist(`view_deduction`)) && <Deduction />}
      {(selectTab === 'provision' && isExist(`view_provision`)) && <Provision />}
      {(selectTab === 'sick_leave' && isExist(`view_sick_leave`)) && <Sick />}
    </Fragment>
  );
};

export default Employees;