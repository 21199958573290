import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import OrganizationService from "../../../../services/OrganizationService";
import { useNavigate } from "react-router-dom";

const CardItem = ({item, setItem, index, setAddModal, setView, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const organizationService = new OrganizationService()
    const navigate = useNavigate()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.name}
            </td>
            <td>
                {item?.organization_attachments?.length || '-'}
            </td>
            {isExist("view_organization") && <td className="text-center">
                <i className="la la-eye cursor-pointer" 
                    onClick={()=> {
                        navigate('/organization/view-organization', {state:item})
                    }} 
                    style={{
                        fontSize: '22px', 
                        color: '#666'
                    }}
                ></i>
            </td>}
            <td className="text-center">
                {(isExist("edit_organization") || isExist("delete_organization") ) && <Dropdown>
                    <Dropdown.Toggle
                        className="light sharp i-false"
                    >
                        <i className="la la-ellipsis-h" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isExist("edit_organization") && <Dropdown.Item onClick={()=> {
                            navigate('/organization/edit-organization', {state:item})
                        }}> 
                        <i className='la la-edit'></i> {Translate[lang]?.edit}</Dropdown.Item>}
                        {isExist("delete_organization") && <Dropdown.Item onClick={()=> setDeleteModal(true)}>
                        <i className='la la-trash'></i> {Translate[lang]?.delete}</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </td>
            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={item?.name}
                      deletedItem={item}
                      modelService={organizationService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                    />}
            </tr>
    )
}
export default CardItem;