import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import HasAllowanceService from "../../../services/HasAllowanceService"
import Pagination from "../../common/Pagination/Pagination"
import NoData from "../../common/NoData"
import { Translate } from "../../Enums/Tranlate"
import { Card, Col, Row, Table } from "react-bootstrap"
import Loader from "../../common/Loader"

const HasAllowances = () => {
    const [data, setData] = useState([])
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const hasAllowanceService = new HasAllowanceService()


    return <Card>
        <Card.Body className={`${hasData === 0 && 'text-center'} `}>
          {loading && <div style={{height: '300px'}}>
              <Loader />
            </div>}
            {(hasData === 1 && !loading) && <Table responsive>
              <thead>
                <tr className='text-center'>
                  <th>
                    <strong>I.D</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.employee_name}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.department}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.allowance_name}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.assign_date}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.fee}</strong>
                  </th>
                  <th>
                    <strong>{Translate[lang]?.type}</strong>
                  </th>
                </tr>
              </thead>

              <tbody className="table-body">
                  {data?.map((item, index) =>{
                      return <tr key={index} className='text-center'>
                      <td>
                          <strong>{item.id}</strong>
                      </td>
                      <td>
                          {item?.employee?.name}
                      </td>
                      <td>
                          {item?.employee?.department?.name}
                      </td>
                      <td>{item.allowance?.name}</td>
                      <td>{!!item.month_from_num ? item.month_from_num?.split('T')[0] : "-"}</td>
                      <td>{item.fee}</td>
                      <td>{item?.type}</td>
                    </tr>
                  })}
              </tbody>
            </Table>}
            {hasData === 0 && <NoData />}
            <Pagination
                setData={setData}
                service={hasAllowanceService}
                shouldUpdate={shouldUpdate}
                setHasData={setHasData}
                setLoading={setLoading}
            />
          </Card.Body>
    </Card>
}
export default HasAllowances;