import { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import { useSelector } from "react-redux";
import './style.scss'
import NoData from "../../common/NoData";

const History = () => {
    const requestsTabs = ['vacations', 'sick_leave', "salary_certificate"]
    const [data, setData] = useState([
        // {employee: {name: 'Mu', department:{name: 'Developer'}}, type: 'emergency_leave', status: 'approved', date: '22/9/2024', no: 4  },
        // {employee: {name: 'Mu', department:{name: 'Developer'}}, type: 'annual_leave', status: 'rejected', date: '22/9/2024', no: 4  },
        // {employee: {name: 'Mu', department:{name: 'Developer'}}, type: 'emergency_leave', status: 'approved', date: '22/9/2024', no: 4  }
    ])
    const [hasData, setHasData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [selectTab, setSelectTab] = useState('vacations')
    const lang = useSelector(state=> state.auth?.lang)

    return <>
        <Card className="mb-3">
        <Card.Body className="p-0">
          <div className="tabs-div">
            {requestsTabs?.map((tab,index)=>{
                return <p
                key={index}
                className={`${tab === selectTab ? "activeP" : "" }`}
                onClick={() => setSelectTab(tab)}
              >
                {Translate[lang][tab]}
              </p>
            })}
          </div>
         </Card.Body>
        </Card>

        {selectTab === 'vacations' && <Card className="mt-4">
            <Card.Body>
            {hasData === 1 && data?.map((req, index) => {
                return <div className="req-card py-4 mb-0" key={index}>
                    <div className="title-card">
                        <p>{Translate[lang].employee} <span>{req?.employee?.name}</span> {Translate[lang].is_requesting} <span>{Translate[lang][req?.type]}</span> {Translate[lang].for} <span>{req?.no}</span> {Translate[lang].days}</p>
                    </div>
                    <p className="mb-0">{req.date}</p>
                    <div className="date">
                        <span style={{
                            color: req.status === "rejected" ? "#ff4847" : "#08194f",
                            border: req.status === "rejected" ? "1px solid #ff4847" : "1px solid #08194f",
                            padding: '6px 8px',
                            borderRadius: '5px',
                        }}>{Translate[lang][req.status]}</span>
                    </div>
                </div>
            })}
            {hasData === 0 && <div className="text-center">
                <NoData />
            </div>}
            {/* <Pagination
                    setData={setData}
                    service={allowanceService}
                    shouldUpdate={shouldUpdate}
                    setHasData={setHasData}
                    setLoading={setLoading}
            /> */}
            </Card.Body>
        </Card>}

        {selectTab === 'sick_leave' && <Card className="mt-4">
            <Card.Body>
            {hasData === 1 && data?.map((req, index) => {
                return <div className="req-card py-4 mb-0" key={index}>
                    <div className="title-card">
                        <p>{Translate[lang].employee} <span>{req?.employee?.name}</span> {Translate[lang].request_sick_leave} <span>{req?.no}</span> {Translate[lang].days}</p>
                    </div>
                    <p className="mb-0">{req.date}</p>
                    <div className="date">
                        <span style={{
                            color: req.status === "rejected" ? "#ff4847" : "#08194f",
                            border: req.status === "rejected" ? "1px solid #ff4847" : "1px solid #08194f",
                            padding: '6px 8px',
                            borderRadius: '5px',
                        }}>{Translate[lang][req.status]}</span>
                    </div>
                </div>
            })}
            {hasData === 0 && <div className="text-center">
                <NoData />
            </div>}
            {/* <Pagination
                    setData={setData}
                    service={allowanceService}
                    shouldUpdate={shouldUpdate}
                    setHasData={setHasData}
                    setLoading={setLoading}
            /> */}
            </Card.Body>
        </Card>}

        {selectTab === 'salary_certificate' && <Card className="mt-4">
            <Card.Body>
            {hasData === 1 && data?.map((req, index) => {
                return <div className="req-card py-4 mb-0" key={index}>
                    <div className="title-card">
                        <p>{Translate[lang].employee} <span>{req?.employee?.name}</span> {Translate[lang].request_salary_certificate}</p>
                    </div>
                    <p className="mb-0">{req.date}</p>
                    <div className="date">
                        <span style={{
                            color: req.status === "rejected" ? "#ff4847" : "#08194f",
                            border: req.status === "rejected" ? "1px solid #ff4847" : "1px solid #08194f",
                            padding: '6px 8px',
                            borderRadius: '5px',
                        }}>{Translate[lang][req.status]}</span>
                    </div>
                </div>
            })}
            {hasData === 0 && <div className="text-center">
                <NoData />
            </div>}
            {/* <Pagination
                    setData={setData}
                    service={allowanceService}
                    shouldUpdate={shouldUpdate}
                    setHasData={setHasData}
                    setLoading={setLoading}
            /> */}
            </Card.Body>
        </Card>}
    </>
}
export default History;