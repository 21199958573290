import React from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import AddLoansModal from "./AddLoansModal";
import LoansHistory from "./LoansHistory";
import LoanService from "../../../services/LoanService";
import './style.scss'

const LoanManagement = () => {
    const [data, setData] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const loanService = new LoanService()
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const tabs = ["has_loans", "not_started", "loans_history"]
    const [selectTab, setSelectTab] = useState('has_loans')

  return (
    <div className="department">
      <Card className="mb-3">
        <Card.Body className="search-card d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-75">
            <div className="custom-tabs-div">
              {tabs?.map((tab,index)=>{
                  return <p
                    key={index}
                    className={`${tab === selectTab ? "activeP" : "" }`}
                    onClick={() => {
                      setSelectTab(tab)
                      setShouldUpdate(prev => !prev)
                    }}
                  >
                    <span></span> {Translate[lang][tab]}
                  </p>
              })}
            </div>
          </div>
          {(isExist('add_loans') && selectTab === 'has_loans') && <div className="add-department">
            <Button variant="primary" className='me-2 h-75' onClick={()=> {
              setAddModal(true) 
            }}>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.loans}
            </Button>
          </div>}
        </Card.Body >
      </Card>
      
      {(selectTab === 'has_loans' || selectTab === "not_started")&& <Row>
        <Col lg={12}>
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.employee_name}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.department}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.loan}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.no_of_installments}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.no_of_payments_made}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.remaining}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.installments}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.payment_start_date}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.note}</strong>
                    </th>
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem 
                            index= {index}
                            key= {index}
                            item={item}
                            selectTab={selectTab}
                            setShouldUpdate={setShouldUpdate}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
              <Pagination
                  setData={setData}
                  service={loanService}
                  shouldUpdate={shouldUpdate}
                  setHasData={setHasData}
                  setLoading={setLoading}
                  param={{status: selectTab === "not_started" ? "not_started" : 'ongoing'}}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>}

      {selectTab === 'loans_history' && <LoansHistory />}

      {addModal && 
        <AddLoansModal
          addModal={addModal} 
          setShouldUpdate={setShouldUpdate}
          setAddModal={()=> setAddModal(false)}
      />}

    </div>
  );
};

export default LoanManagement;
