import { Card, Table } from "react-bootstrap";
import { Translate } from "../../Enums/Tranlate";
import NoData from "../../common/NoData";
import { useState } from "react";
import Loader from "../../common/Loader";
import { useSelector } from "react-redux";
import Pagination from "../../common/Pagination/Pagination";
import LoanService from "../../../services/LoanService";

const LoansHistory = () => {
    const [data, setData] = useState([])
    const [hasData, setHasData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const loanService = new LoanService()
    const lang = useSelector(state=> state.auth.lang)

    return <Card>
        <Card.Body className={`${hasData === 0 && 'text-center'} `}>
        {loading && <div style={{height: '300px'}}>
            <Loader />
          </div>}
          {(hasData === 1 && !loading) && <Table responsive>
            <thead>
              <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.employee_name}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.department}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.loan}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.type}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.month}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.year}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.no_of_installments}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.remaining_installment}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.payment_start_date}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.attachments}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.note}</strong>
                    </th>
              </tr>
            </thead>

            <tbody className="table-body">
                {data?.map((item, index) =>{
                    return <tr key={index} className='text-center'>
                      <td>
                          <strong>{item.id}</strong>
                      </td>
                      <td>{item?.employee?.name}</td>
                      <td>{item?.employee?.department?.name}</td>
                      <td>{item.loan}</td>
                      <td>{item?.type}</td>
                      <td>{item?.month || '-'}</td>
                      <td>{item?.year || '-'}</td>
                      <td>{item.no_of_installments}</td>
                      <td style={{display: 'grid', gap: '8px', gridTemplateColumns: 'auto auto auto'}}>
                          {item?.loan_dates?.map(res=> {
                              return <p
                                  key={res?.id}
                                  style={{
                                      padding: '8px',
                                      border: '1px solid #dedede',
                                      borderRadius: '5px',
                                      textAlign: 'center',
                                      cursor: 'pointer',
                                      gap: '8px'
                                  }} 
                                  className="d-flex mb-0"
                              >
                                  <span>{Translate[lang][res?.month?.toLowerCase()]}</span>
                                  <span>{res?.year}</span>
                              </p>
                          })}
                      </td>
                      <td>{item.start_date?.split('T')[0]}</td>
                      <td>
                        {item.lead_attachments?.length > 0 ? item.lead_attachments?.map((att=>(
                            <a href={att.url} target='_black' rel="noreferrer">
                                <i className="la la-file-pdf" style={{fontSize: '2.5rem'}}></i>
                            </a>
                        ))) : '-'}
                      </td>
                      <td>{item?.note || '-'}</td>
                    </tr>
                })}
            </tbody>
          </Table>}
          {hasData === 0 && <NoData />}
          <Pagination
              setData={setData}
              service={loanService}
              shouldUpdate={shouldUpdate}
              setHasData={setHasData}
              setLoading={setLoading}
              param={{ status: "finished"}}
          />
        </Card.Body>
    </Card>
}
export default LoansHistory;