import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import Assets from "./Assets";
import PrintProfile from "../../../Enums/PrintProfile";

const Profile = ({data}) => {
    const lang = useSelector(state=> state.auth.lang)

    return <div className="profile">
        <Row>
            <Col md='6'>
                <Card>
                    <Card.Body>
                    <div>
                    {/* <a href="#" class="edit-icon"><i class="fa fa-pencil"></i></a> */}
                        <h3 class="card-title">{Translate[lang].personal_informations}</h3>
                        <ul class="personal-info">
                            <li>
                            <div class="title">{Translate[lang].department}</div>
                            <div class="text">{data?.department?.name}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].civil_id}</div>
                            <div class="text">{data?.civil_id}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].expiry_date}</div>
                            <div class="text">{data?.expiry_date?.split('T')[0] || '-'}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].job_title}</div>
                            <div class="text">{data?.job_title}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].passport_no}</div>
                            <div class="text">{data?.passport_no || '-'}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].passport_expiry_date}</div>
                            <div class="text">{data?.passport_expiry_date?.split('T')[0] || '-'}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].nationality}</div>
                            <div class="text">{data?.nationality}</div>
                            </li>
                            <li>
                            <div class="title">{Translate[lang].salary}</div>
                            <div class="text">{data?.salary} {Translate[lang].kwd}</div>
                            </li>
                        </ul>
                    </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md='6'>
                <Card style={{height:'auto'}}>
                    <Card.Body>
                            <h3 class="card-title">{Translate[lang].bank}</h3>
                            <ul class="personal-info">
                                <li>
                                    <div class="title">{Translate[lang].iban}</div>
                                    <div class="text">{data?.iban}</div>
                                </li>
                            </ul>
                    </Card.Body>
                </Card>
                <Card style={{height:'auto'}}>
                    <Card.Body>
                            <h3 class="card-title">{Translate[lang].loan}</h3>
                            {!!data?.loans?.length ? data?.loans?.map(res=>{
                                    return <ul class="personal-info mb-3" key={res.id}>
                                        <li>
                                            <div class="title">{Translate[lang].loan}</div>
                                            <div class="text">{res?.loan} {Translate[lang].kwd}</div>
                                        </li>
                                        <li>
                                            <div class="title">{Translate[lang].no_of_installments}</div>
                                            <div class="text">{res?.no_of_installments}</div>
                                        </li>
                                        <li>
                                            <div class="title">{Translate[lang].start_date}</div>
                                            <div class="text">{res?.start_date?.split('T')[0]}</div>
                                        </li>
                                    </ul> 
                                }) : Translate[lang].there_are_no_data}
                    </Card.Body>
                </Card>
                <Card style={{height:'auto'}}>
                    <Card.Body>
                            <h3 class="card-title">{Translate[lang].allowances}</h3>
                            {data?.allowance?.length > 0 ? <ul class="personal-info">
                                {data?.allowance?.map(allow=>{
                                    return <li key={allow?.id}>
                                        <div class="title">{allow?.name}</div>
                                        <div class="text">{allow?.fee}</div>
                                    </li>
                                })}
                            </ul> : Translate[lang].there_are_no_data} 
                    </Card.Body>
                </Card>
            </Col>
            <Col md='6'></Col>
            <Col md={12}>
                <Assets assets={data.employee_assets} />
            </Col>
            <Col md={12}>
                <Card>
                    <Card.Body>
                        <h3 class="card-title">{Translate[lang].benefits}</h3>
                        <Table responsive>
                        <thead>
                        <tr className='text-center'>
                            <th>
                            <strong>I.D</strong>
                            </th>
                            <th>
                            <strong>{Translate[lang]?.type}</strong>
                            </th>
                            <th>
                            <strong>{Translate[lang]?.increase}</strong>
                            </th>
                            <th>
                            <strong>{Translate[lang]?.month}</strong>
                            </th>
                            <th>
                            <strong>{Translate[lang]?.year}</strong>
                            </th>
                        </tr>
                        </thead>

                        <tbody className="table-body">
                            {data?.benefits?.map((item, index) =>{
                                return <tr key={index} className='text-center'>
                                <td>
                                    <strong>{item?.id}</strong>
                                </td>
                                <td>
                                    {item?.type}
                                </td>
                                <td>
                                    {item?.increase}
                                </td>
                                <td>
                                    {item?.month}
                                </td>
                                <td>
                                    {item?.year}
                                </td>
                                </tr>
                            })}
                        </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </Col>

            <Col md='6'>
                <Card >
                    <Card.Body>
                                <h3 class="card-title">{Translate[lang].provision}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">{Translate[lang].accrued_leave}</div>
                                        <div class="text">{data?.accrued_leave}</div>
                                    </li>
                                    <li>
                                        <div class="title">{Translate[lang].provision}</div>
                                        <div class="text">{Number.parseFloat(data?.provision_KWD).toFixed(3)}</div>
                                    </li>
                                </ul>
                        </Card.Body>
                    </Card>
            </Col>
            <Col md='6'>
                <Card >
                    <Card.Body>
                                <h3 class="card-title">{Translate[lang].sick_leave}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title">{Translate[lang].sick_leave}</div>
                                        <div class="text">{data?.sick_leave}</div>
                                    </li>
                                </ul>
                        </Card.Body>
                    </Card>
            </Col>
            <Col md={6}>
                <Card >
                    <Card.Body>
                                <h3 class="card-title">{Translate[lang].indemnity}</h3>
                                <ul class="personal-info">
                                    <li>
                                        <div class="title w-50">{Translate[lang].accrued_leave}</div>
                                        <div class="text w-50 px-5">{data?.accrued_leave}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].daily_indemnity}</div>
                                        <div class="text w-50 px-5">{data?.dailyIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].exceeding_years_indemnity}</div>
                                        <div class="text w-50 px-5">{data?.exceedingYearsIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].first5YearsIndemnity}</div>
                                        <div class="text w-50 px-5">{data?.first5YearsIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].monthly_indemnity}</div>
                                        <div class="text w-50 px-5">{data?.monthlyIndemnity}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].paid_leave_balance_amount}</div>
                                        <div class="text w-50 px-5">{data?.paidLeaveBalanceAmount}</div>
                                    </li>
                                    <li>
                                        <div class="title w-50">{Translate[lang].total_indemnity}</div>
                                        <div class="text w-50 px-5">{data?.totalIndemnity}</div>
                                    </li>
                                </ul>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <div className="mb-4">
            <Button onClick={()=> PrintProfile(data, lang)} variant="outline-primary">{Translate[lang].print}</Button>
        </div>
    </div>
}
export default Profile;