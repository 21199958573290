import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import { months } from "../../../Enums/Fixed";
import EmployeesService from "../../../../services/EmployeesService";
import LoanService from "../../../../services/LoanService";
import YearDropDown from "../../../Enums/YearDropDown";
// import AllowanceService from "../../../../services/AllowanceService";

const AddLoansModal = ({addModal, setAddModal, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        employee: '',
        loan: '',
        loan_type: '',
        no_of_installments: '',
        payment_start_date: '',
        month: '',
        year: "",
        note: ''
    })
    const types = [
        {label: 'Add to Payroll', value: 'add_to_payroll'},
        {label: 'Cash', value: 'cash'},
        {label: 'Cheque', value: 'cheque'},
        {label: 'deposit', value: 'deposit'},
    ]
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const loanService = new LoanService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(()=>{
      new EmployeesService()?.getList().then(res=>{
          if(res?.status === 200){
              let data = res?.data?.data?.data?.map(emp=>{
                  return {
                      ...emp,
                      label: emp?.name,
                      value: emp.id
                  }
              })
              setEmployeesOptions(data)
          }
      })
    },[])

    const submit = (e) =>{
        e.preventDefault();
        let data ={
            employee_id: formData?.employee?.id,
            start_date: formData?.payment_start_date,
            loan: formData?.loan,
            type: formData?.loan_type?.value,
            no_of_installments: formData?.no_of_installments
        }
        if(formData?.loan_type?.value === "add_to_payroll") data['month']= formData?.month?.label
        if(formData?.loan_type?.value === "add_to_payroll") data['year']= formData?.year?.label
        if(!!formData?.note) data['note']= formData?.note

        setLoading(true)
        loanService.create(data)?.then(res=>{
        if(res && res?.status === 201){
                toast.success('Loan Added Successfully')
                setShouldUpdate(prev=> !prev)
                setAddModal()
            }
            setLoading(false)
        }).catch(()=>setLoading(false))
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
            <AvForm className='form-horizontal' onValidSubmit={submit}>
                <Modal.Header>
                    <Modal.Title>{Translate[lang]?.add} {Translate[lang]?.loans}</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                        onClick={()=>{
                            setAddModal()
                        }}
                        >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <label className="text-label">{Translate[lang]?.employee}</label>
                            <Select
                                value={formData?.employee}
                                name="employee"
                                placeholder={Translate[lang]?.select}
                                options={employeesOptions}
                                onChange={(e)=> setFormData({...formData, employee: e})}
                            />
                        </Col>
                        {/* <Col md={6}>
                            <label className="text-label">{Translate[lang]?.department}</label>
                            <p>Developer</p>
                        </Col> */}
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.loan}
                                type='number'
                                placeholder={Translate[lang]?.loan}
                                bsSize="lg"
                                name='loan'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.loan}
                                onChange={(e) => setFormData({...formData, loan: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <label className="text-label">{Translate[lang]?.type}</label>
                            <Select
                                value={formData?.loan_type}
                                name="loan_type"
                                placeholder={Translate[lang]?.select}
                                options={types}
                                onChange={(e)=> setFormData({...formData, loan_type: e})}
                            />
                        </Col>
                        {formData?.loan_type?.label === 'Add to Payroll' && <>
                        <Col md={3} className="mb-3">
                            <label>{Translate[lang].month}</label>
                            <Select
                                placeholder={Translate[lang]?.select}
                                options={months?.map(res=>{
                                    return{
                                        label: Translate[lang][res.toLocaleLowerCase()],
                                        value: res
                                    }
                                })}
                                value={formData?.month}
                                onChange={(e) => setFormData({...formData, month: e})}
                            />
                        </Col>
                        <Col md={3}>
                            <YearDropDown params={formData} changeParams={(e) => setFormData({...formData, year: e})} />
                        </Col>
                        </>}
                        <Col md={6} className="mb-3">
                            <AvField
                                label={Translate[lang]?.no_of_installments}
                                type='number'
                                placeholder={Translate[lang]?.no_of_installments}
                                bsSize="lg"
                                name='no_of_installments'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                min={'1'}
                                value={formData.no_of_installments}
                                onChange={(e) => setFormData({...formData, no_of_installments: e.target.value})}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <AvField
                                label={Translate[lang]?.payment_start_date}
                                type='date'
                                placeholder={Translate[lang]?.payment_start_date}
                                bsSize="lg"
                                name='payment_start_date'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.payment_start_date}
                                onChange={(e) => setFormData({...formData, payment_start_date: e.target.value})}
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <AvField
                                label={Translate[lang]?.note}
                                type='text'
                                placeholder={Translate[lang]?.note}
                                bsSize="lg"
                                name='note'
                                value={formData.note}
                                onChange={(e) => setFormData({...formData, note: e.target.value})}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={setAddModal} variant="danger light">
                        {Translate[lang]?.close}
                    </Button>
                    <Button 
                        variant="primary" 
                        type='submit'
                        disabled={loading}
                    >{Translate[lang]?.add}</Button>
                </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default AddLoansModal;