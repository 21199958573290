import React, { Fragment } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import Pagination from "../../common/Pagination/Pagination";
import { Translate } from "../../Enums/Tranlate";
import CardItem from "./CardItem";
import AddAllowancesModal from "./AddAllowancesModal";
import './style.scss'
import AllowanceService from "../../../services/AllowanceService";
import AllowancesSetting from "./AllowancesSetting";
import HasAllowances from "./HasAllowances";

const Allowances = () => {
    const [data, setData] = useState([])
    const [addModal, setAddModal] = useState(false)
    const [item, setItem] = useState({})
    const [hasData, setHasData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const allowanceService = new AllowanceService()
    const Auth = useSelector((state) => state.auth?.auth);
    const isExist = (data) => Auth?.admin?.admin_roles?.includes(data);
    const tabs = ["allowances", "has_allowances", "allowances_setting"]
    const [selectTab, setSelectTab] = useState('allowances')

  return (
    <div className="department">
      <Card className="mb-3">
        <Card.Body className="search-card d-flex justify-content-between p-3 align-items-center">
          <div className="input-group w-75">
            <div className="custom-tabs-div">
              {tabs?.map((tab,index)=>{
                if(isExist(`view_allowances`)){
                  return <p
                  key={index}
                  className={`${tab === selectTab ? "activeP" : "" }`}
                  onClick={() => setSelectTab(tab)}
                >
                  <span></span> {Translate[lang][tab]}
                  </p>
                } else {
                  return <></>
                }
              })}
            </div>
          </div>
          {selectTab === 'allowances' && <div className="add-department">
            {isExist('add_allowances') && <Button variant="primary" className='me-2 h-75' onClick={()=> { 
              setItem({})
              setAddModal(true) 
            }}>
              <i className="la la-plus mx-1"></i>
              {Translate[lang]?.add} {Translate[lang]?.allowances}
            </Button>}
          </div>}
        </Card.Body >
      </Card>
      
      {selectTab === 'allowances' && <Row>
        <Col lg={12}>
          {/* {selectTab === 'allowances' && <Card> */}
          <Card>
            <Card.Body className={`${hasData === 0 && 'text-center'} `}>
            {loading && <div style={{height: '300px'}}>
                <Loader />
              </div>}
              {(hasData === 1 && !loading) && <Table responsive>
                <thead>
                  <tr className='text-center'>
                    <th>
                      <strong>I.D</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.name}</strong>
                    </th>
                    <th>
                      <strong>{Translate[lang]?.fees}</strong>
                    </th>
                    {selectTab === 'has_allowances' && <th></th>}
                    {selectTab !== 'has_allowances' && <th></th>}
                  </tr>
                </thead>

                <tbody className="table-body">
                    {data?.map((item, index) =>{
                        return <CardItem 
                            index= {index}
                            key= {index}
                            item={item}
                            setItem={setItem}
                            selectTab={selectTab}
                            setAddModal={setAddModal}
                            setShouldUpdate={setShouldUpdate}
                        />
                    })}
                </tbody>
              </Table>}
              {hasData === 0 && <NoData />}
              <Pagination
                  setData={setData}
                  service={allowanceService}
                  shouldUpdate={shouldUpdate}
                  setHasData={setHasData}
                  setLoading={setLoading}
              />
            </Card.Body>
          </Card>

          {/* {selectTab === 'has_allowances' && <Card>
            <Card.Body>
            </Card.Body>
          </Card>} */}
        </Col>
      </Row>}

      {selectTab === 'has_allowances' && <HasAllowances />}
      {selectTab === 'allowances_setting' && <AllowancesSetting />}

      {addModal && 
        <AddAllowancesModal
          item={item} 
          addModal={addModal} 
          setShouldUpdate={setShouldUpdate}
          setAddModal={()=> setAddModal(false)}
      />}

    </div>
  );
};

export default Allowances;
