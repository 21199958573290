import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import { months } from "../../../Enums/Fixed";
import EmployeesService from "../../../../services/EmployeesService";
import LoanService from "../../../../services/LoanService";
import YearDropDown from "../../../Enums/YearDropDown";

const FreezModal = ({modal, setModal, item, selectDate, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        employee: '',
        month: '',
        year: '',
    })
    const [loading, setLoading] = useState(false)
    const loanService = new LoanService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(() => {
        setFormData({
            id: selectDate?.id,
            month: {
                label: selectDate?.month,
                value: selectDate?.month
            },
            year: {
                label: selectDate?.year,
                value: selectDate?.year
            },
        })
    },[selectDate])

    const submit = (e) =>{
        e.preventDefault();
        if(item?.loan_dates?.findIndex(res=> res?.year === formData?.year?.value && res?.month === formData?.month?.value) !== -1){
            toast.error('Select Another Date')
            return
        }
        let data = {
            month: formData?.month?.value,
            year: formData?.year?.value
        }

        setLoading(true)
        loanService.updateDate(formData?.id, data)?.then(res=>{
            if(res && res?.status === 200){
                toast.success('Loan Date Updated Successfully')
                setShouldUpdate(prev=> !prev)
                setModal()
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={modal} onHide={()=>{
            setModal()
            }}>
            <AvForm className='form-horizontal' onValidSubmit={submit}>
                <Modal.Header>
                    <Modal.Title>{Translate[lang]?.edit}</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                        onClick={()=>{
                            setModal()
                        }}
                        >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className="mb-3">
                            <label>{Translate[lang].month}</label>
                            <Select
                                placeholder={Translate[lang]?.select}
                                options={months?.map(res=>{
                                    return{
                                        label: Translate[lang][res.toLocaleLowerCase()],
                                        value: res
                                    }
                                })}
                                value={formData?.month}
                                onChange={(e) => setFormData({...formData, month: e})}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <label>{Translate[lang].year}</label>
                            <Select
                                placeholder={Translate[lang]?.select}
                                options={[
                                    {label: new Date().getFullYear(), value: new Date().getFullYear()},
                                    {label: new Date().getFullYear()+1, value: new Date().getFullYear()+1},
                                    {label: new Date().getFullYear()+2, value: new Date().getFullYear()+2},
                                ]}
                                value={formData?.year}
                                onChange={(e) => setFormData({...formData, year: e})}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={setModal} variant="danger light">
                        {Translate[lang]?.close}
                    </Button>
                    <Button 
                        variant="primary" 
                        type='submit'
                        disabled={loading}
                    >{Translate[lang]?.edit}</Button>
                </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default FreezModal;