import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import AllowanceService from "../../../../services/AllowanceService";

const CardItem = ({item, setItem, index,selectTab, setAddModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const allowanceService = new AllowanceService()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.name}
            </td>
            <td>{item.fee}</td>
            {selectTab === 'has_allowances' && <td>
            <Form.Check
                type="switch"
                id={`custom-switch${index}`}
                // checked={status}
                disabled={!isExist("edit_admin")}
                // onChange={(e) => changeStatusToggle(e)}
                />
            </td> }
            {selectTab !== 'has_allowances' && <td>
                {(isExist('edit_allowances') || isExist('delete_allowances')) && <Dropdown>
                    <Dropdown.Toggle
                        className="light sharp i-false"
                    >
                        <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {isExist('edit_allowances') && <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setAddModal(true)
                        }}> 
                            <i className='la la-edit'></i> {Translate[lang]?.edit}
                        </Dropdown.Item>}
                        {isExist('delete_allowances') && <Dropdown.Item onClick={()=> setDeleteModal(true)}>
                        <i className='la la-trash'></i> {Translate[lang]?.delete}
                        </Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>}
            </td>}
            {deleteModal && <DeleteModal
                      open={deleteModal}
                      titleMsg={item?.name}
                      deletedItem={item}
                      modelService={allowanceService}
                      onCloseModal={setDeleteModal}
                      setShouldUpdate={setShouldUpdate}
                    />}
            </tr>
    )
}
export default CardItem;