import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/loan";

export default class LoanService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  updateDate(id, data){
    return http.put(`${apiEndpoint}/date/${id}`, data)
  }
}