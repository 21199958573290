import { useEffect, useState } from "react"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import EmployeesService from "../../../../services/EmployeesService"
import { Translate } from "../../../Enums/Tranlate"
import { useSelector } from "react-redux"
import Select from "react-select"
import { months } from "../../../Enums/Fixed"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { toast } from "react-toastify"

const EndOfService = () => {
    const [formData, setFormData] = useState({})
    const [employee, setEmployee] = useState("")
    const [date, setDate] = useState("")
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [experienceDays, setExperienceDays] = useState(0)
    const lang = useSelector(state=> state.auth?.lang)
    const employeesService = new EmployeesService()
 
    useEffect(()=>{
        employeesService?.getList().then(res=>{
            if(res?.status){
                let data = res?.data?.data?.data?.map(emp=>{
                    return {
                        label: emp?.name,
                        value: emp?.id
                    }
                })
                setEmployeesOptions(data)
            }
        })
    },[])

    const submit = () => {
        if(!employee?.value){
            toast.error('Select Employee')
            return
        }
        employeesService?.endOfService(employee?.value, date).then(res=>{
            if(res?.status){
                let info = res?.data?.data
                const date1 = new Date(date);
                const date2 = new Date(info?.start_date);

                // Calculate the difference in milliseconds
                const differenceInTime = date1 - date2;

                // Convert the difference from milliseconds to days
                const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
                setFormData(info)
                setExperienceDays(differenceInDays)
            }
        })
    }

    return <div className="end-of-service">
    <Card className="mb-3">
        <Card.Body className="p-3">
            <AvForm
                className='form-horizontal'
                onValidSubmit={submit}>
                <Row>
                    <Col md={6} sm={12}>
                        <label>{Translate[lang].employee}</label>
                        <Select
                            placeholder={Translate[lang].select}
                            options={employeesOptions}
                            value={employee}
                            onChange={(e)=> setEmployee(e)}
                        />
                    </Col>
                    <Col md={3} sm={6}>
                        <AvField
                            label={Translate[lang]?.date}
                            type='date'
                            placeholder={Translate[lang]?.date}
                            bsSize="lg"
                            name='date'
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: Translate[lang].field_required
                                }
                            }}
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </Col>
                    <Col md={3} sm={6} className="d-flex align-items-center">
                    <Button 
                        variant="primary" 
                        type='submit'
                        disabled={loading}
                    >{Translate[lang]?.submit}</Button>
                    </Col>
                </Row>
            </AvForm>
        </Card.Body>
    </Card>

    {formData?.id && <Card>
        <Card.Body>
            <div className='text-center'>
                <h4 className="mb-4">End of Service Statement</h4>
            </div>
            <div>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                    <h5><span className='px-2 py-2' style={{background: '#dedede57'}}>Name:</span> {formData.name}</h5>
                    <h5><span className='px-2 py-2' style={{background: '#dedede57'}}>EMP:</span> {formData?.id}</h5>
                </div>
                <div className="d-flex mb-3 justify-content-between align-items-center">
                    <h5><span className='px-2 py-2' style={{background: '#dedede57'}}>Basic Salary:</span> {formData.salary}</h5>
                    <h5><span className='px-2 py-2' style={{background: '#dedede57'}}>Extra allowance:</span></h5>
                    <h5><span className='px-2 py-2' style={{background: '#dedede57'}}>EOS Type:</span> Normal Resignation</h5>
                </div>
            </div>
            <Table responsive className="mt-5 mb-3 w-50">
                <tbody>
                    <tr>
                        <td style={{background: '#dedede57'}}>EOS Date</td>
                        <td>{date}</td>
                    </tr>
                    <tr>
                        <td style={{background: '#dedede57'}}>Hiring Date</td>
                        <td>{formData?.start_date?.split('T')[0]}</td>
                    </tr>
                </tbody>
            </Table>
            <Table responsive className="mb-3 w-50">
                <tbody>
                    <tr>
                        <td style={{background: '#dedede57'}}>Experience Days</td>
                        <td>{experienceDays}</td>
                    </tr>
                    <tr>
                        <td style={{background: '#dedede57'}}>Unpaid Leave</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{background: '#dedede57'}}>Net Service Days</td>
                        <td>{}</td>
                    </tr>
                </tbody>
            </Table>

            <div className="my-4 py-2 px-2 first d-flex justify-content-between">
                <p>During the first five years</p>
                <p>15 / 365 x {experienceDays}  =  {((15/365)*experienceDays).toFixed(3)}</p>
            </div>
            <div className="mb-4 py-2 px-2 second d-flex justify-content-between">
                <p>After five years</p>
                {((5*365) > experienceDays) ? <p>26 / 365 x 0  =  0.000</p> : <p>26 / 365 x {experienceDays}  =  {((26/365)*experienceDays).toFixed(3)}</p>}
            </div>
            <div className="mb-4 py-1 px-2 second d-flex justify-content-between">
                <p>Total amount</p>
                <p>{((15/365)*experienceDays).toFixed(3)} + {((5*365) < experienceDays) ? ((26/365)*experienceDays).toFixed(3) : 0.000}  =  {(((15/365)*experienceDays)+(((5*365) < experienceDays) ? ((26/365)*experienceDays) : 0)).toFixed(3)} KWD</p>
            </div>

            <div className="d-flex justify-content-between bottom-table align-items-center mt-5">
                <Table responsive className="w-50">
                    <tbody>
                        <tr>
                            <td style={{background: '#dedede57'}}>Leave Balance</td>
                            <td>{date}</td>
                        </tr>
                    </tbody>
                </Table>
                <p className="m-0 tot1">1150.000</p>
            </div>
            <div className="d-flex justify-content-between bottom-table align-items-center mt-5">
                <Table responsive className="w-50">
                    <tbody>
                        <tr>
                            <td style={{background: '#dedede57'}}>Notice Period</td>
                            <td className="d-flex justify-content-around w-auto">
                                <span>0 Days</span>
                                <span>0 Month</span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p className="m-0 tot1">0.000</p>
            </div>
        </Card.Body>
    </Card>}
    </div>
}
export default EndOfService