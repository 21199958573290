import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import CertificateModal from "./CertificateModal";
import logo from '../../../images/logo-color.svg';
import {AvField, AvForm} from "availity-reactstrap-validation";
import Select from "react-select";
import EmployeesService from "../../../services/EmployeesService";

const Certificate = () => {
  const [formData, setFormData] = useState({
    employee: "",
    type: {label: "Salary Certification", value: "Salary Certification"},
    employer_name: "",
    leave_req: "",
    absence_to: "",
    absence_from: "",
    month: ""
  })
  const types = [
    {label: "Salary Certification", value: "Salary Certification"},
    {label: "Internship Certification", value: "Internship Certification"},
    {label: "Employee Contract", value: "Employee Contract"},
    {label: "Company Work Rules", value: "Company Work Rules"},
    {label: "Employee Custody Form", value: "Employee Custody Form"},
    {label: "Job Offer Letter", value: "Job Offer Letter"},
    {label: "Joining Letter", value: "Joining Letter"},
    {label: "Salary Form", value: "Salary Form"},
    {label: "Warning", value: "Warning"},
    {label: "Vacation Form", value: "Vacation Form"},
  ]
  const [employeesOptions, setEmployeesOptions] = useState([])
  const employeesService = new EmployeesService()
  const lang = useSelector(state=> state.auth?.lang)
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')

  useEffect(()=>{
    employeesService?.getList().then(res=>{
        if(res?.status === 200){
            let data = res?.data?.data?.data?.map(emp=>{
                return {
                    ...emp,
                    label: emp?.name,
                    value: emp.id
                }
            })
            setEmployeesOptions(data)
        }
    })
  },[])

  const printProjects = () => {
      let printWindow = window.open("", "_blank");
      let htmlCode = `
        <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Certificate</title>
            </head>
            <body style="direction: ${lang==='en' ? 'ltr' : 'rtl'}; font-family: Cairo, sans-serif; padding: 0; margin: 1rem auto 1.5; width: 85%;">
                <div style='text-align: left'>
                    <img src=${logo} alt='logo' style="height: 80px; margin-top: 24px" />  
                </div>  
                <div style="font-size: 22px; margin-top: 1.5rem">
                ${text}
              </div>
                
            </body>
        </html>
    `;
    printWindow.document.write(htmlCode);

    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
    }, 1500);
  }

  return (
    <Fragment>
      <Card className="mb-3 certificate">
        <Card.Body>
        <div className="tabs-div p-0" style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto auto',
          gap: '12px',
          border: 'none',
        }}>
            {types?.map((tab,index)=>{
                return <p
                key={index}
                style={{borderRadius: '5px'}}
                className={`${tab.label === formData?.type?.label ? "activeP" : "" }`}
                onClick={() => setFormData({...formData, type: tab, employee: ""})}
              >
                {tab.label}
              </p>
            })}
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body className="d-flex justify-content-end">
          <div className="input-group w-50"></div>
          <div>
            {show && <Button variant="secondary" className='mx-2' onClick={printProjects}>
                {Translate[lang]?.print}
            </Button>}
            <Button variant="primary" onClick={()=> {
              setModal(true) 
            }}>
                {Translate[lang]?.create} 
            </Button>
            
          </div>
        </Card.Body >
      </Card>

      {show && <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
              {/* <Button variant="secondary" className="mt-5" onClick={printProjects}>{Translate[lang].print}</Button> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>}
      {modal && 
        <CertificateModal
          modal={modal} 
          formData={formData}
          setFormData={setFormData}
          setShow={setShow}
          setText={setText}
          setModal={()=> setModal(false)}
      />}
    </Fragment>
  );
};

export default Certificate;
