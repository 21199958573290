import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap"
import {AvField, AvForm} from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Translate } from "../../../Enums/Tranlate";
import AllowanceService from "../../../../services/AllowanceService";

const ChangePasswordModal = ({modal, setModal, item, setShouldUpdate})=>{
    const [formData, setFormData] = useState({
        new_password: '',
        confirm_password: '',
    })
    const [loading, setLoading] = useState(false)
    const allowanceService = new AllowanceService()
    const lang = useSelector(state=> state.auth.lang)

    useEffect(() => {
        setFormData({
            id: item?.id,
            name: item?.name,
            fee: item?.fee
        })
    },[item])

    const submit = (e) =>{
        e.preventDefault();
        let data ={
            name: formData?.name,
            fee: formData?.fee
        }

        // setLoading(true)
        // if(isAdd){
        //     allowanceService.create(data)?.then(res=>{
        //         if(res && res?.status === 201){
        //             toast.success('Allowance Added Successfully')
        //             setShouldUpdate(prev=> !prev)
        //             setModal()
        //         }
        //         setLoading(false)
        //     }).catch(()=>setLoading(false))
        // } else {
        //     allowanceService.update(formData?.id, data)?.then(res=>{
        //         if(res && res?.status === 200){
        //             toast.success('Allowance Updated Successfully')
        //             setShouldUpdate(prev=> !prev)
        //             setModal()
        //         }
        //         setLoading(false)
        //     }).catch(()=>setLoading(false))
        // }
    }

    return(
        <Modal className={lang === 'en' ? "en fade addProduct" : "ar fade addProduct"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={modal} onHide={()=>{
            setModal()
            }}>
                <AvForm
                    className='form-horizontal'
                    onValidSubmit={submit}>
            <Modal.Header>
            <Modal.Title>{Translate[lang]?.change_password}</Modal.Title>
            <Button
                variant=""
                className="close"
                style={{right: lang === 'en' ? '0' : 'auto', left: lang === 'ar' ? '0' : 'auto'}}
                onClick={()=>{
                    setModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.new_password}
                                type='text'
                                placeholder={Translate[lang]?.new_password}
                                bsSize="lg"
                                name='new_password'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.new_password}
                                onChange={(e) => setFormData({...formData, new_password: e.target.value})}
                            />
                        </Col>
                        <Col md={6}>
                            <AvField
                                label={Translate[lang]?.confirm_password}
                                type='text'
                                placeholder={Translate[lang]?.confirm_password}
                                bsSize="lg"
                                name='confirm_password'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: Translate[lang].field_required
                                    }
                                }}
                                value={formData.confirm_password}
                                onChange={(e) => setFormData({...formData, confirm_password: e.target.value})}
                            />
                        </Col>
                    </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={setModal} variant="danger light">
            {Translate[lang]?.close}
            </Button>
            <Button 
                    variant="primary" 
                    type='submit'
                    disabled={loading}
                >{Translate[lang]?.edit}</Button>
            </Modal.Footer>
            </AvForm>
        </Modal>)
}

export default ChangePasswordModal;