import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Select from 'react-select';
import AllowanceService from "../../../services/AllowanceService";
import EmployeesService from "../../../services/EmployeesService";
import { toast } from "react-toastify";

const AllowancesSetting = () => {
    const [type, setType] = useState({
        vacations: false,
        sick_leave: false,
        excluded_employees_from_setting: false,
    })
    const [allowancesVacationsOptions, setAllowancesVacationsOptions] = useState([])
    const [allowancesSickOptions, setAllowancesSickOptions] = useState([])
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        allowances_in_vacations: [],
        allowances_not_in_vacations: [],
        allowances_in_sick_leave: [],
        allowances_not_in_sick_leave: [],
        excluded_employees_from_setting: []
    })
    const allowanceService = new AllowanceService()
    const employeesService = new EmployeesService()
    const lang = useSelector(state=> state.auth?.lang)

    useEffect(()=>{
        allowanceService?.getList().then(res=> {
            let info = res.data?.data?.data?.map(item => {
                return {
                    label: item?.name,
                    value: item?.id,
                    id: item?.id
                }
            })
            setAllowancesVacationsOptions(info)
            setAllowancesSickOptions(info)
        })

        allowanceService?.getAllowanceSetting().then(res=> {
            let data = res?.data?.data
            setType({
                vacations: !!data?.allowanceInVacations?.length || !!data?.allowanceNotInVacations?.length,
                sick_leave: !!data?.allowanceInSick?.length || !!data?.allowanceNotInSick?.length,
                excluded_employees_from_setting: !!data?.excludeEmployee?.length
            })
            setFormData({
                allowances_in_vacations: data?.allowanceInVacations?.map(info => {
                    return {
                        ...info,
                        label: info?.name,
                        value: info?.id,
                    }
                }),
                allowances_not_in_vacations: data?.allowanceNotInVacations?.map(info => {
                    return {
                        ...info,
                        label: info?.name,
                        value: info?.id,
                    }
                }),
                allowances_in_sick_leave: data?.allowanceInSick?.map(info => {
                    return {
                        ...info,
                        label: info?.name,
                        value: info?.id,
                    }
                }),
                allowances_not_in_sick_leave: data?.allowanceNotInSick?.map(info => {
                    return {
                        ...info,
                        label: info?.name,
                        value: info?.id,
                    }
                }),
                excluded_employees_from_setting:  data?.excludeEmployee?.map(info => {
                    return {
                        ...info?.employee,
                        label: info?.employee?.name,
                        value: info?.employee?.id,
                    }
                }),
            })
            // let info = res.data?.data?.data?.map(item => {
            //     return {
            //         label: item?.name,
            //         value: item?.id,
            //         id: item?.id
            //     }
            // })
        })

        employeesService?.getList().then(res=> {
            let info = res.data?.data?.data?.map(item => {
                return {
                    label: item?.name,
                    value: item?.id,
                    id: item?.id
                }
            })
            setEmployeesOptions(info)
        })
    },[])

    const submit = () => {
        let data = {
            inVacations: formData?.allowances_in_vacations?.map(res=> res?.id),
            notInVacations: formData?.allowances_not_in_vacations?.map(res=> res?.id),
            inSick: formData?.allowances_in_sick_leave?.map(res=> res?.id),
            notInSick: formData?.allowances_not_in_sick_leave?.map(res=> res?.id),
            excludeEmployee: formData?.excluded_employees_from_setting?.map(res=> res?.id),
        }

        setLoading(true)
        allowanceService?.postAllowanceSetting(data).then(res=> {
            if(res?.status){
                toast.success("Allowances Setting Added Successfully.")
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    return <Card>
        <Card.Body>
            <h4 className="mb-3">{Translate[lang].allowances_options}</h4>
            <div className="d-flex" style={{gap: '16px'}}>
                <label>
                    <input type="checkbox" checked={type?.vacations} onClick={e=> {
                        if(!e.target?.checked && !type?.sick_leave){
                            setType({...type, vacations: e.target?.checked, excluded_employees_from_setting: false})
                            return
                        }
                        setType({...type, vacations: e.target?.checked})
                    }} name='vacations' className="mx-2" />
                    {Translate[lang].vacations}
                </label>
                <label>
                    <input type="checkbox" checked={type?.sick_leave} onClick={e=> {
                        if(!e.target?.checked && !type?.vacations){
                            setType({...type, sick_leave: e.target?.checked, excluded_employees_from_setting: false})
                            return
                        }
                        setType({...type, sick_leave: e.target?.checked})
                    }} name='sick_leave' className="mx-2" />
                    {Translate[lang].sick_leave}
                </label>
                <label>
                    <input type="checkbox" checked={type.excluded_employees_from_setting} disabled={!type?.sick_leave && !type?.vacations} onClick={e=> setType({...type, excluded_employees_from_setting: e.target?.checked})} name='excluded_employees_from_setting' className="mx-2" />
                    {Translate[lang].excluded_employees_from_setting}
                </label>
            </div>

            {type?.vacations && <div className="mt-5">
                <h4 className="mb-3">{Translate[lang].vacations}</h4>
                <Row>
                    <Col md={6}>
                        <label className="text-label">{Translate[lang]?.allowances_in_vacations}</label>
                        <Select
                            value={formData?.allowances_in_vacations}
                            name="allowances_in_vacations"
                            isMulti
                            placeholder={Translate[lang]?.select}
                            options={allowancesVacationsOptions?.filter(res=> ![...formData?.allowances_in_vacations, ...formData?.allowances_not_in_vacations].some(comp => comp.id === res.id))}
                            onChange={(e)=> setFormData({...formData, allowances_in_vacations: e})}
                        />
                    </Col>
                    <Col md={6}>
                        <label className="text-label">{Translate[lang]?.allowances_not_in_vacations}</label>
                        <Select
                            value={formData?.allowances_not_in_vacations}
                            name="allowances_not_in_vacations"
                            placeholder={Translate[lang]?.select}
                            options={allowancesVacationsOptions?.filter(res=> ![...formData?.allowances_in_vacations, ...formData?.allowances_not_in_vacations].some(comp => comp.id === res.id))}
                            isMulti
                            onChange={(e)=> setFormData({...formData, allowances_not_in_vacations: e})}
                        />
                    </Col>
                </Row>
            </div>}
            {type?.sick_leave && <div className="mt-5">
                <h4 className="mb-3">{Translate[lang].sick_leave}</h4>
                <Row>
                    <Col md={6}>
                        <label className="text-label">{Translate[lang]?.allowances_in_sick_leave}</label>
                        <Select
                            value={formData?.allowances_in_sick_leave}
                            name="allowances_in_sick_leave"
                            isMulti
                            placeholder={Translate[lang]?.select}
                            options={allowancesSickOptions?.filter(res=> ![...formData?.allowances_in_sick_leave, ...formData?.allowances_not_in_sick_leave].some(comp => comp.id === res.id))}
                            onChange={(e)=> setFormData({...formData, allowances_in_sick_leave: e})}
                        />
                    </Col>
                    <Col md={6}>
                        <label className="text-label">{Translate[lang]?.allowances_not_in_sick_leave}</label>
                        <Select
                            value={formData?.allowances_not_in_sick_leave}
                            name="allowances_not_in_sick_leave"
                            placeholder={Translate[lang]?.select}
                            options={allowancesSickOptions?.filter(res=> ![...formData?.allowances_in_sick_leave, ...formData?.allowances_not_in_sick_leave].some(comp => comp.id === res.id))}
                            isMulti
                            onChange={(e)=> setFormData({...formData, allowances_not_in_sick_leave: e})}
                        />
                    </Col>
                </Row>
            </div>}

            {type?.excluded_employees_from_setting && <div className="mt-5">
                <h4 className="mb-3">{Translate[lang].excluded_employees_from_setting}</h4>
                <Row>
                    <Col md={12}>
                        <Select
                            value={formData?.excluded_employees_from_setting}
                            name="excluded_employees_from_setting"
                            isMulti
                            placeholder={Translate[lang]?.select}
                            options={employeesOptions}
                            onChange={(e)=> setFormData({...formData, excluded_employees_from_setting: e})}
                        />
                    </Col>
                </Row>
            </div>}

            <div className="mt-5 text-right">
                <Button disabled={loading} variant='primary' onClick={submit}>{Translate[lang].submit}</Button>
            </div>
        </Card.Body>
    </Card>
}
export default AllowancesSetting;