import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import DeleteModal from "../../../common/DeleteModal";
import { Translate } from "../../../Enums/Tranlate";
import AllowanceService from "../../../../services/AllowanceService";

const CardItem = ({item, setItem, index,selectTab, setModal, setShouldUpdate}) =>{
    const [deleteModal, setDeleteModal] = useState(false)
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)
    const allowanceService = new AllowanceService()

    return(
        <tr key={index} className='text-center'>
            <td>
                <strong>{item.id}</strong>
            </td>
            <td>
                {item?.employee?.name}
            </td>
            <td>
                {item?.employee?.department?.name}
            </td>
            <td>
                {item?.employee?.job_title}
            </td>
            <td>
                {item?.employee?.user_name}
            </td>
            <td>
                {item?.employee?.password}
            </td>
            <td>
                <i className="la la-eye" style={{fontSize: '27px'}}></i>
            </td>
            <td>
                {/* {isExist('edit_requests') &&  */}
                <Dropdown>
                    <Dropdown.Toggle
                        className="light sharp i-false"
                    >
                        <i className="la la-ellipsis-v" style={{fontSize: '27px'}}></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=> {
                            setItem(item)
                            setModal(true)
                        }}> 
                            <i className='la la-edit'></i> {Translate[lang]?.change_password}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {/* } */}
            </td>
            </tr>
    )
}
export default CardItem;