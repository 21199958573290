import { useEffect, useState } from "react"
import SalariesService from "../../../../services/SalariesService"
import { Button, Card, Col, Row, Table } from "react-bootstrap"
import EmployeesService from "../../../../services/EmployeesService"
import { Translate } from "../../../Enums/Tranlate"
import { useSelector } from "react-redux"
import Select from "react-select"
import MonthDropDown from "../../../Enums/MonthDropDown"
import YearDropDown from "../../../Enums/YearDropDown"
import logo from '../../../../images/logo-color.svg'
import { months } from "../../../Enums/Fixed"

const SalarySlip = () => {
    const [formData, setFormData] = useState({})
    const [employee, setEmployee] = useState("")
    const [employeesOptions, setEmployeesOptions] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state=> state.auth?.lang)
    const salariesService = new SalariesService()
    const employeesService = new EmployeesService()
    const [params, setParams] = useState({
        month: {
          label: Translate[lang][months[new Date().getMonth()].toLocaleLowerCase()],
          value: months[new Date().getMonth()]
      },
        year: {
          label: `${new Date().getFullYear()}`,
          value: new Date().getFullYear()
        }
    })

    const changeParams = (e, name) => {
        setParams({...params, [name]: e})
        setShouldUpdate(prev=> !prev)
    }
 
    useEffect(()=>{
        employeesService?.getList().then(res=>{
            if(res?.status){
                let data = res?.data?.data?.data?.map(emp=>{
                    return {
                        label: emp?.name,
                        value: emp?.id
                    }
                })
                setEmployeesOptions(data)
            }
        })
    },[])

    useEffect(()=>{
        if(!!employee?.value){
            salariesService.getSalarySlip(employee?.value, {
                month: params.month.value,
                year: params.year.value
            }).then(res=>{
                if(res?.status){
                    setFormData(res?.data?.data)
                }
            })
        }
    },[employee, shouldUpdate])

    const print = () => {
        let printWindow = window.open("", "_blank");
        let htmlCode = `
            <html>
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Salary Slip</title>
                </head>
                <body style="width: 85%; font-family: Cairo, sans-serif; margin: auto; padding: 0">
                    <div style='text-align: left'>
                        <img src=${logo} alt='logo' style="height: 100px; margin-top: 24px;" />  
                    </div>  
                    
                    <div style="margin-top: 16px; display: flex; justify-content: space-between; align-items: center; margin-top: 24px;">
                        <div>
                            <h4 style='margin-bottom: 4px; margin-top: 0'>${formData?.name}</h4>
                            <p style='margin-bottom: 0'>${formData?.job_title}</p>
                        </div>
                        <div>
                            <p>${new Date().toDateString()}</p>
                        </div>
                    </div>
                        <table style='margin-top: 3rem; width: 100%'>
                            <tbody>
                                <tr style='height: 45px; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'>01</td>
                                    <td style='width: 50%; padding: 0 12px'>${Translate[lang].basic_salary}</td>
                                    <td style='width: 25%; padding: 0 12px'>${formData?.salary} ${Translate[lang].kwd}</td>
                                </tr>
                                <tr style='height: 45px; background: #dedede57; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'>02</td>
                                    <td style='width: 50%; padding: 0 12px'>${Translate[lang].deduction}</td>
                                    <td style='width: 25%; padding: 0 12px'>${formData?.deduction} ${Translate[lang].kwd}</td>
                                </tr>
                                <tr style='height: 45px; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'>03</td>
                                    <td style='width: 50%; padding: 0 12px'>${Translate[lang].allowances}</td>
                                    <td style='width: 25%; padding: 0 12px'>${formData?.allowance} ${Translate[lang].kwd}</td>
                                </tr>
                                <tr style='height: 45px; background: #dedede57; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'>04</td>
                                    <td style='width: 50%; padding: 0 12px'>${Translate[lang].bonus}</td>
                                    <td style='width: 25%; padding: 0 12px'>${formData?.bonus} ${Translate[lang].kwd}</td>
                                </tr>
                                <tr style='height: 45px; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'>05</td>
                                    <td style='width: 50%; padding: 0 12px'>${Translate[lang].loan}</td>
                                    <td style='width: 25%; padding: 0 12px'>${formData?.loan_deduction} ${Translate[lang].kwd}</td>
                                </tr>
                                <tr style='height: 45px; padding: 8px 5px'>
                                    <td style='width: 25%; padding: 0 12px'></td>
                                    <td style='width: 50%; padding: 0 12px'></td>
                                    <td style='width: 25%; padding: 0 12px'>
                                        <strong>
                                        ${formData?.payroll} KWD
                                        </strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </body>
            </html>
        `;
        printWindow.document.write(htmlCode);

        printWindow.document.close();

        setTimeout(() => {
        printWindow.print();
        }, 1500);
    }
    return <>
    <Card className="mb-3">
        <Card.Body className="p-3">
            <Row>
                <Col md={6} sm={12}>
                    <label>{Translate[lang].employee}</label>
                    <Select
                        placeholder={Translate[lang].select}
                        options={employeesOptions}
                        value={employee}
                        onChange={(e)=> setEmployee(e)}
                    />
                </Col>
                <Col md={3} sm={6}>
                  <MonthDropDown
                    params={params} 
                    changeParams={changeParams} 
                  />
                </Col>
                <Col md={3} sm={6}>
                  <YearDropDown
                    params={params} 
                    changeParams={changeParams} 
                  />
                </Col>
            </Row>
        </Card.Body>
    </Card>

    {formData?.id && <Card>
        <Card.Body>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h4>{formData.name}</h4>
                    <p>{formData.job_title}</p>
                </div>
                <div>
                    <p>{new Date().toDateString()}</p>
                </div>
            </div>
            <Table responsive className="mt-5 mb-3">
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>{Translate[lang].basic_salary}</td>
                        <td></td>
                        <td>{formData?.salary} {Translate[lang].kwd}</td>
                    </tr>
                    <tr style={{background: '#dedede57'}}>
                        <td>02</td>
                        <td>{Translate[lang].deduction}</td>
                        <td></td>
                        <td>{formData?.deduction} {Translate[lang].kwd}</td>
                    </tr>
                    <tr>
                        <td>03</td>
                        <td>{Translate[lang].allowances}</td>
                        <td></td>
                        <td>{formData?.allowance} {Translate[lang].kwd}</td>
                    </tr>
                    <tr style={{background: '#dedede57'}}>
                        <td>04</td>
                        <td>{Translate[lang].bonus}</td>
                        <td></td>
                        <td>{formData?.bonus} {Translate[lang].kwd}</td>
                    </tr>
                    <tr>
                        <td>05</td>
                        <td>{Translate[lang].loan}</td>
                        <td></td>
                        <td>{formData?.loan_deduction} {Translate[lang].kwd}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <strong>
                            {formData?.payroll} KWD
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <div className="d-flex justify-content-between">
                <div></div>
                <Button variant='primary' onClick={print}>{Translate[lang].print}</Button>
            </div>
        </Card.Body>
    </Card>}
    </>
}
export default SalarySlip