import BaseService from "./BaseService";
import http from "./HttpService";
import { API_BASE_URL_ENV } from "../jsx/common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/allowance";
const apiAllowanceProfileEndpoint = API_BASE_URL_ENV() + "/allowance/assignAllowance";
const apiAllowanceSettingEndpoint = API_BASE_URL_ENV() + "/allowance/allowanceSetting";

export default class AllowanceService extends BaseService {
  constructor() {
    super(apiEndpoint);
  }

  getAllowanceForEmp(id,type) {
    return http.get(`${apiAllowanceProfileEndpoint}/${id}?type=${type}`);
  }

  getAllowanceSetting() {
    return http.get(apiAllowanceSettingEndpoint);
  }

  postAllowanceSetting(data) {
    return http.post(apiAllowanceSettingEndpoint, data);
  }

  allowanceForEmp(data) {
    return http.post(apiAllowanceProfileEndpoint, data);
  }
}