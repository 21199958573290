import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import HolidayService from '../../../services/HolidayService';
import Calendar from 'react-awesome-calendar';
import './style.css'

const Holiday = () => {
    const [data, setData] = useState([])

    useEffect(()=>{
        let textStorage = `Holiday${new Date().getFullYear()}`
        let getHoliday = localStorage.getItem(textStorage)

        if(!!getHoliday){
            setData(JSON.parse(getHoliday))
        } else {
            new HolidayService().getList().then(res=>{
                if(res?.status){
                    let response = res?.data?.data?.map(item=>{
                        return {
                            ...item,
                            color: '#FEA257',
                            from: `${item?.date}T00:00:00+00:00`,
                            to: `${item?.date}T24:00:00+00:00`,
                            title: item?.name
                        }
                    })
                    setData(response)
                    localStorage.setItem(textStorage, JSON.stringify(response))
                }
            })
        }
    },[])

    return <Card className='holiday'>
        <Card.Body>
            <Calendar events={data} />
        </Card.Body>
    </Card>
}
export default Holiday;